import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import {
  ResponseStep,
  CustomFieldConfig,
} from "../../../models/onboardingApplication/onboardingApplicationInterfaces";
import { Check, Dash } from "react-bootstrap-icons";
import {
  capitalizeFirstLetter,
  formatAsNationalPhoneNum,
} from "../../../utils/formatting";

import ResponseStepField from "./responseStepField";

export const FULLY_CAPITALIZED_WORDS = ["ssn", "itin", "tin", "dob", "ach"];
export interface StepSummaryProps {
  step:
    | {
        type: "review";
        slug: string;
        assets: {
          keyFilterList: string[];
        };
      }
    | {
        type: "custom";
        slug: string;
        assets: {
          customFields: CustomFieldConfig[];
        };
      }
    | {
        type: string;
        slug: string;
        assets: Record<string, any>;
      };
  i: number;
  response: ResponseStep;
  keyFilterList: string[];
}

const ApplicationStepSummary = ({
  step,
  i,
  response,
  keyFilterList,
}: StepSummaryProps) => {
  const [fieldsRendered, setFieldsRendered] = useState([]);

  useEffect(() => {
    setFieldsRendered([]);
    if (!response) {
      return;
    }
    getFieldsToRender(i, step.type);
  }, [response]);

  // ----------------------------------- Helper Methods -------------------------------------------
  const formatTitle = (title: string) => {
    if (!title) {
      return "";
    }
    const words = title.split(/(?=[A-Z]|-)/);
    const formattedWords = words.map((word) => {
      // Replace every hyphen found with a space
      const formattedWord = word.replace(/-([a-z])/g, (_, letter) => letter);
      // If the word is one of the ones that should be fully capitalized, then do so.
      if (FULLY_CAPITALIZED_WORDS.includes(formattedWord.toLowerCase())) {
        return formattedWord.toUpperCase();
      }
      // Otherwise, capitalize the first letter of the word.
      return capitalizeFirstLetter(formattedWord);
    });
    return formattedWords.join(" ");
  };

  const formatAsCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  // * "Select" and "Autocomplete" fields are saved with the value of the option selected, not the label.
  //   This is not user-friendly, as the user saw the label when making a selection.
  //   This function replaces the value saved with the label of the option so that the user is not confused.
  const replaceValueWithLabel = (value: string) => {
    // Get the field associated with the provided value from the response.
    const fieldAssociatedWithValue = Object.keys(response.fields).find(
      (f) => response.fields[f] === value
    );

    // Find the custom field config associated with the field.
    const assets = step.assets as {
      customFields: CustomFieldConfig[];
    };
    const customField = assets?.customFields?.find(
      (f) => f.fieldKey === fieldAssociatedWithValue
    );

    // Use the config to find the field option associated with the provided value.
    const customOption = customField?.fieldOptions?.find(
      (o) => o.value === value
    );

    // If a custom field or option is not found, return the value.
    if (!customField || !customOption) {
      if (typeof value === "string") {
        return formatString(value);
      }
      return value;
    }

    // Return the label for the saved value
    return customOption?.label;
  };

  const formatString = (answer: string | number) => {
    const answerString = answer.toString();
    if (
      answerString &&
      FULLY_CAPITALIZED_WORDS.includes(answerString.toLowerCase())
    ) {
      return answerString.toUpperCase();
    }
    return capitalizeFirstLetter(answerString);
  };

  const formatAnswer = (answer: any, fieldType: string) => {
    if (answer === true || answer.toString().toLowerCase() === "true") {
      return <Check className="mb-0" />;
    }
    if (answer === false || answer.toString().toLowerCase() === "false") {
      return <Dash className="mb-0" />;
    }
    switch (fieldType) {
      case "phone":
        return formatAsNationalPhoneNum(answer);
      case "date":
        return moment(answer).format("MM/DD/YYYY");
      case "dollarInput":
      case "currency":
        return formatAsCurrency(parseFloat(answer));
      case "percent":
        return capitalizeFirstLetter(answer) + "%";
      case "select":
      case "autocomplete":
        return replaceValueWithLabel(answer);
      default:
        if (Array.isArray(answer)) {
          return answer.map((a: string) => formatString(a)).join(", ");
        }
        return formatString(answer);
    }
  };

  // ------------------------------------------- Render Methods --------------------------------------------

  const renderCustomFields = (i: number, stepType: string) => {
    // Define an empty fields array to push fields into for rendering.
    const fields: any = [];
    // CUSTOM STEP
    const customAssets = step.assets as {
      customFields: CustomFieldConfig[];
      allowRepeats?: boolean;
    };
    const rFields = response.fields;
    const allowRepeats = customAssets.allowRepeats || false;

    // If allowRepeats, there could be multiple answers per step with a suffix such as __0, __1, etc.
    if (allowRepeats) {
      customAssets?.customFields?.map((field: CustomFieldConfig) => {
        const fKey = field.fieldKey;
        const baseFieldKey = fKey.replace(/__\d+$/, "");

        // Find the highest suffix number to determine how many times the field was answered.
        let repeatCount = 0;
        Object.keys(rFields).forEach((key) => {
          if (key.startsWith(baseFieldKey + "__")) {
            const suffixNum = parseInt(key.slice(baseFieldKey.length + 2), 10);
            if (!isNaN(suffixNum) && suffixNum > repeatCount) {
              repeatCount = suffixNum;
            }
          }
        });

        // Loop through all possible suffixes and render the field if it exists.
        for (let i = 0; i <= repeatCount; i++) {
          const fKeyWithSuffix = baseFieldKey + "__" + i;
          if (
            !rFields[fKeyWithSuffix] ||
            rFields[fKeyWithSuffix].toString().toLowerCase() === "false" ||
            keyFilterList.includes(fKeyWithSuffix)
          ) {
            continue;
          }

          // If the field is a rate, format it as a percent.
          const fieldType = fKey.includes("rate") ? "percent" : field.fieldType;
          const formattedKey = `(${i + 1}) ${formatTitle(fKey)}`;
          const formattedValue = formatAnswer(
            rFields[fKeyWithSuffix],
            fieldType
          );
          fields.push({
            key: formattedKey,
            value: formattedValue,
          });
        }
      });
    } else {
      // If allowRepeats is false, there will only be one answer per field.
      // Follows a similar logic to the above, but without the suffixes.
      customAssets?.customFields?.map((field: CustomFieldConfig) => {
        const fKey = field.fieldKey;
        if (!rFields[fKey] || keyFilterList.includes(fKey)) {
          return;
        }
        const fieldType = fKey.includes("rate") ? "percent" : field.fieldType;
        const formattedKey = formatTitle(fKey);
        const formattedValue = formatAnswer(rFields[fKey], fieldType);
        fields.push({
          key: formattedKey,
          value: formattedValue,
        });
      });
    }
    const renderedKeys: string[] = [];
    const renderedFields: any = fields.map((field: any, index: number) => {
      // If there are duplicates in the field config (creative measures for condtionaly displaying fields), skip rendering subsequent fields with the same key.
      if (renderedKeys.includes(field.key)) {
        return;
      }
      renderedKeys.push(field.key);
      return (
        <ResponseStepField key={index} title={field.key} value={field.value} />
      );
    });
    // Set the fields to be rendered in state and return.
    setFieldsRendered(renderedFields);
  };

  const renderTruStageFields = () => {
    // Define an empty fields array to push fields into for rendering.
    const fields: any = [];

    Object.entries(response.fields).forEach(([key, answer]) => {
      const title =
        key === "CI"
          ? "Credit Insurance"
          : key === "DP"
          ? "Debt Protection"
          : key;
      const value = answer === true ? "Interested" : "Not Interested";
      fields.push(<ResponseStepField title={title} value={value} />);
    });

    setFieldsRendered(fields);
  };

  const getFieldsToRender = (i: number, stepType: string) => {
    const fields: any = [];
    let fieldOrder: { key: string; type: string }[] = [];
    switch (stepType) {
      // If a custom or TruStage step, follow special rendering logic.
      case "custom":
        renderCustomFields(i, stepType);
        return;
      case "truStage":
        renderTruStageFields();
        return;
      // For all other step types, render the fields in the order defined in the switch statement.
      // If the fieldOrder is empty, nothing will be rendered.
      case "start":
        fieldOrder = [
          { key: "applicationId", type: "text" },
          { key: "email", type: "text" },
          { key: "phone", type: "phone" },
        ];
        break;
      case "name":
        fieldOrder = [
          { key: "fullName", type: "text" },
          { key: "terms", type: "checkbox" },
        ];
        break;
      case "address":
        fieldOrder = [
          { key: "address", type: "text" },
          { key: "apartment", type: "text" },
          { key: "city", type: "text" },
          { key: "state", type: "text" },
          { key: "zip", type: "text" },
        ];
        break;
      case "ssn":
        fieldOrder = [
          { key: "ssn", type: "ssn" },
          { key: "birthday", type: "date" },
          { key: "identityConfirmation", type: "checkbox" },
        ];
        break;
      case "additional-contact":
        fieldOrder = [
          { key: "email", type: "text" },
          { key: "phone", type: "phone" },
        ];
        break;
      case "idv-vouched":
        fieldOrder = [{ key: "vouchedVerificationSuccess", type: "checkbox" }];
        break;
      case "docusign":
        fieldOrder = [{ key: "status", type: "text" }];
        break;
      case "loan-rate":
        fieldOrder = [
          { key: "loanAmount", type: "currency" },
          { key: "termInMonths", type: "text" },
          { key: "rate", type: "percent" },
          { key: "monthlyPayment", type: "currency" },
        ];
      case "cd-interest":
        fieldOrder = [{ key: "principal", type: "currency" }];
        break;
      case "existing-core-accounts":
        fieldOrder = [{ key: "accountNumber", type: "text" }];
      // Cases where we don't want anything to render.
      case "plaid":
      case "plaid-idv":
      case "plaid-income":
      case "people":
      case "payment":
      case "payment-status":
      case "review":
      case "success":
        break;
      case "initial-deposit":
        fieldOrder = Object.keys(response.fields).map((key) => ({
          key,
          type: "currency",
        }));
        break;
      default:
        fieldOrder = Object.keys(response.fields).map((key) => ({
          key,
          type: "text",
        }));
        break;
    }

    fieldOrder.forEach((field) => {
      const { key, type } = field;
      if (response.fields.hasOwnProperty(key) && !keyFilterList.includes(key)) {
        let value = response.fields[key];
        value &&
          fields.push(
            <ResponseStepField
              title={formatTitle(key)}
              value={formatAnswer(value, type)}
            />
          );
      }
    });

    setFieldsRendered(fields);
  };

  // ------------------------------------------- Final Render ----------------------------------------------

  // If there are no fields to render, return null. This prevents a step without useful data from rendering,
  // such as an optional step where every prompt is skipped.
  if (fieldsRendered.length === 0) {
    return null;
  }

  return (
    <div className="d-flex flex-column w-100" style={{ overflow: "hidden" }}>
      {/* Render fields - These are defined by getFieldsToRender() */}
      {fieldsRendered}
    </div>
  );
};
export default ApplicationStepSummary;
