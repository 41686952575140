import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api, * as API from "../../utils/api";
import * as localStorage from "../../utils/localStorage";
import jwtDecode from "jwt-decode";
import { IMG_BASE_URL } from "../../constants";
import { applicationStatusSlice } from "../applicationStatusSlice";
import {
  MyUser,
  Roles,
  LoginAPIResponse,
  UserAPIResponse,
  RoleAPIResponse,
} from "./userInterfaces";
import { mobileCheck } from "../../utils/helpers";
import { mixpanel } from "../../utils/mixpanel";

type MyUserState = MyUser | null;

const initialState: MyUserState = null;

function jwtToUser(jwt: string): UserAPIResponse {
  const {
    user: {
      id,
      attributes: { firstName, lastName, email, phone, imageId, imageVersion },
    },
  }: {
    user: {
      id: number;
      type: "weserve";
      attributes: {
        firstName: string;
        lastName: string;
        email: string;
        phone?: string;
        imageId: string;
        imageVersion: string;
      };
    };
  } = jwtDecode(jwt);
  return {
    id,
    firstName,
    lastName,
    email,
    phone: phone || "",
    imageId,
    imageVersion,
  };
}

export const setUserInfo = createAsyncThunk<MyUser, LoginAPIResponse>(
  "myUser/setUserInfo",
  async (data, thunkApi) => {
    // set auth token for all future requests
    API.setTokens(data.token);
    localStorage.setTokens(data.token);

    const user = jwtToUser(data.token.auth);
    (window as any).Appcues.identify(user.id, { name: user.firstName });
    mixpanel.identify(user.id);

    const { organization }: RootState = thunkApi.getState() as any;
    const {
      data: { records: userRoles },
    } = await api.get<RoleAPIResponse>(
      `/organizations/${organization.id}/financial-roles`
    );
    const userRoleNames = userRoles?.map((r) => r.name) || [];
    const roles: Roles = {
      portalAdmin: userRoleNames.includes("portalAdmin"),
      analyst: userRoleNames.includes("analyst"),
      portalUser: userRoleNames.includes("portalUser"),
    };

    const isAdminPortalUser =
      roles.portalAdmin || roles.analyst || roles.portalUser;

    const isMobile = mobileCheck();
    const invalidMobileUser = isMobile && isAdminPortalUser;

    thunkApi.dispatch(applicationStatusSlice.actions.setAuthorization(true));

    return {
      invalidMobileUser,
      roles: roles,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      imgUrl:
        user.imageId === "avatar"
          ? null
          : `${IMG_BASE_URL}/${user.imageVersion}/${user.imageId}`,
      userId: user.id,
      isAdminPortalUser,
    };
  }
);

export const login = createAsyncThunk<
  MyUser,
  { email: string; password: string }
>("myUser/login", async ({ email, password }, thunkApi) => {
  try {
    const { data } = await api.post<LoginAPIResponse>("/auth/login", {
      email,
      password: btoa(password),
    });
    const response = await thunkApi.dispatch(setUserInfo(data));
    return response.payload as MyUser;
  } catch (e) {
    return Promise.reject(e);
  }
});

export const logout = createAsyncThunk<void>(
  "myUser/logout",
  async (_, thunkApi) => {
    localStorage.clearTokens();
    API.clearTokens();

    thunkApi.dispatch(applicationStatusSlice.actions.setAuthorization(false));
    thunkApi.dispatch(myUserSlice.actions.clearUser());
  }
);

export const loginByRefreshToken = createAsyncThunk<
  void,
  { refreshToken: string }
>("myUser/loginByRefreshToken", async ({ refreshToken }, thunkApi) => {
  try {
    const { organization }: RootState = thunkApi.getState() as any;
    const { data } = await api.post(
      "/auth/tokens/verify",
      {},
      { headers: { "X-Cotribute-Refresh-Token": refreshToken } }
    );
    await thunkApi.dispatch(setUserInfo(data));
  } catch (e) {
    if (e.response.status === 401) {
      try {
        await api.post(
          "auth/tokens/send",
          {
            path: window.location.pathname,
          },
          { headers: { "X-Cotribute-Refresh-Token": refreshToken } }
        );
      } catch (e) {
        console.error("There was an error generating a magic link", e);
      }
    }
    // Log out the user
    thunkApi.dispatch(logout());

    // Redirect the user to the login-error page
    let org = "";
    const matches = window.location.pathname.match(/organization\/(.*)\//);
    if (matches) org = matches[1];
    const redirectUrl = `/organization/${org.split("/")[0]}/login-error`;
    window.location.replace(redirectUrl);
    return Promise.reject("Unauthorized");
  }
});

export const myUserSlice = createSlice<any, { clearUser: () => null }>({
  name: "myUser",
  initialState: initialState as MyUserState,
  reducers: {
    clearUser: () => {
      return null;
    },
  },
  extraReducers: {
    [setUserInfo.fulfilled.type]: (state, { payload }) => {
      return payload;
    },
    [logout.fulfilled.type]: (state) => {
      return null;
    },
  },
});

export const selectMyUser = (state: RootState) =>
  state.myUser as MyUser | undefined;

export default myUserSlice.reducer;
