import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useForm } from "react-hook-form";

import "./login.scss";
import { Link } from "react-router-dom";

interface Inputs {
  email: string;
}

const LoginErrorPage: React.FC = () => {
  const url = window.location.pathname;
  const slicedUrl = url.slice(0, url.lastIndexOf("/"));
  return (
    <div className="container">
      <div className="text-center" id="login-page">
        <h1 className="mb-4">Please log in to continue.</h1>
        <p>
          If you arrived at this page after following an email link, your
          previous session has expired. If you have a valid account, we will
          email you a fresh access link shortly.
        </p>

        <div className="mt-2 mb-4">
          <Link to={`${slicedUrl}/login`}>
            Already have a username and password? Click here to log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginErrorPage;
